import React from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse, Mask, Row, Col, Fa, Button, View, Container, FormInline } from 'mdbreact';
import FeatureRow from "./components/FeatureRow";
import OffsetAnchor from "../../../../components/offset-anchor";
import styled from 'styled-components';
import Rotation from 'react-rotation';

import Img0 from './assets/360/0.png';
import Img45 from './assets/360/45.png';
import Img90 from './assets/360/90.png';
import Img135 from './assets/360/135.png';
import Img180 from './assets/360/180.png';
import Img225 from './assets/360/225.png';
import Img270 from './assets/360/270.png';
import Img315 from './assets/360/315.png';

const FeaturesCol = styled(Col)`
  margin-top: 70px;
`;

const StyledRotation = styled(Rotation)`
    cursor: ew-resize;
`;

export default class Scene extends React.Component {
    render(){
        return (
            <section className="my-5" >
                <OffsetAnchor id="mogelijkheden" offset={-100}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">De mogelijkheden</h2>
                </OffsetAnchor>
                <p className="lead grey-text w-responsive text-center mx-auto mb-5">
                    Onze photobooth beschikt over een camera met hoge kwaliteit<br />en krachtige snelle hardware om een vlotte werking te garanderen.
                </p>
                <Row>
                    <FeaturesCol md="4">
                        <FeatureRow icon="picture-o" title="Gepersonaliseerde layout">
                            In samenspraak met u ontwerpen wij een gepersonaliseerde lay-out voor het scherm en de prints.
                        </FeatureRow>
                        <FeatureRow icon="print" title="Instant printen">
                            De foto's worden meteen ter plaatse binnen 1 minuut geprint.
                        </FeatureRow>
                        <FeatureRow icon="cubes" title="Modulair systeem">
                            De photobooth kan in hoogte versteld worden.
                            Afhankelijk van de locatie waar de photobooth moet geplaatst worden, kan de printeruitvoer gewijzigd worden.
                        </FeatureRow>
                    </FeaturesCol>
                    <Col md="4" className="text-name text-center">
                        <StyledRotation scroll={false} cycle>
                            <img className="img-fluid" src={Img0} alt="Photobooth" />
                            <img className="img-fluid" src={Img45} alt="Photobooth" />
                            <img className="img-fluid" src={Img90} alt="Photobooth" />
                            <img className="img-fluid" src={Img135} alt="Photobooth" />
                            <img className="img-fluid" src={Img180} alt="Photobooth" />
                            <img className="img-fluid" src={Img225} alt="Photobooth" />
                            <img className="img-fluid" src={Img270} alt="Photobooth" />
                            <img className="img-fluid" src={Img315} alt="Photobooth" />
                        </StyledRotation>
                    </Col>
                    <FeaturesCol md="4">
                        <FeatureRow icon="hand-pointer-o" title="Toegankelijk voor iedereen">
                            De photobooth is door jong én oud zeer eenvoudig te bedienen via een groot touchscreen.
                        </FeatureRow>
                        <FeatureRow icon="comments-o" title="Sociale media">
                            Uw foto's kunnen onmiddellijk gepost worden op sociale media (Facebook).
                        </FeatureRow>
                        <FeatureRow icon="street-view" title="Green screen">
                            Laat uw gasten zelf kiezen waar ze zich bevinden. Dankzij de technologie van het green screen kiezen uw gasten naar waar ze virtueel geteleporteerd worden. De mogelijkheiden zijn oneindig.
                        </FeatureRow>
                    </FeaturesCol>
                </Row>
            </section>
        );
    }
};
import React from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Mask, Row, Col, Fa, Button, View, Container } from 'mdbreact';
import * as Scenes from "./scenes";
import PhotoboothImage from './assets/photobooth-45.png';
import styled from 'styled-components';
import Layout from "~/components/layout";
import OffsetAnchor from "../../components/offset-anchor";

const ImgPhotobooth = styled.img`
  margin: 0 auto;
`;

const StyledContainer = styled(Container)`
  position: absolute;
  top: 110px;
`;

const OrderButton = withRouter(({ history }) => (
    <Button color="white" onClick={() => { history.push('/reserveer') }}>Reserveer</Button>
  ))

export default class Scene extends React.Component {
    render(){
        return (
            <Layout scrolling>
                <OffsetAnchor id="home" offset={-100}>
                    <View>
                        <Mask className="d-flex justify-content-center align-items-center gradient">
                            <StyledContainer>
                                <Row>
                                    <Col md="6" xl="6" className="mt-xl-5" className="white-text text-center text-md-left mt-xl-5 mb-5">
                                        <h1 className="h1-responsive font-weight-bold mt-sm-5">Maak uw evenement onvergetelijk!</h1>
                                        <hr className="hr-light"/>
                                        <h6 className="mb-4">Met onze photobooth maakt u uw evenement nog beter! Of het nu een huwelijk, een communie, bedrijfsfeest of... is, 
    met de <strong>Codibooth</strong> voegt u net die extra touch toe om van uw evenement een onvergetelijk gebeuren te maken.</h6>
                                        <OrderButton />
                                        {/* <Button outline color="white">Meer info</Button> */}
                                    </Col>
                                    <Col md="6" xl="6" className="mt-xl-5">
                                        <ImgPhotobooth src={PhotoboothImage} className="img-fluid"/>
                                    </Col>
                                </Row>
                            </StyledContainer>
                        </Mask>
                    </View>
                </ OffsetAnchor>
                <Container>
                    <Scenes.Features />
                    <hr className="my-5" />
                    <Scenes.Pricing />
                    <hr className="my-5" />
                    {/* <Scenes.Photos />
                    <hr className="my-5" /> */}
                    <Scenes.Contact />
                </Container>
            </Layout>
        );
    }
};
import firebase from 'firebase/app';
import { Col, Container, Row } from 'mdbreact';
import * as moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const ColoredDiv = styled.div`
  background-color: #6db9bc;
`;

const StyledHr = styled.hr`
  background-color: #6db9bc;
  width: 60px;
`;

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            algemeneVoorwwardenUrl: null,
        };

        let storage = firebase.storage();        
        let gsReference = storage.refFromURL('gs://codibooth-65202.appspot.com/public/algemene-voorwaarden.pdf')
        gsReference.getDownloadURL().then(url => this.setState({algemeneVoorwwardenUrl: url}));
    }

    render(){
        return (
            <footer className="page-footer font-small unique-color-dark mt-4">

                <ColoredDiv>
                    <Container>
                        <Row className="py-4 d-flex align-items-center">
                            <Col md="6" lg="5" className="text-center text-md-left mb-4 mb-md-0">
                                <h6 className="mb-0">Volg ons op sociale media!</h6>
                            </Col>
                            <Col md="6" lg="7" className="text-center text-md-right">
                                <a className="fb-ic" href="https://www.facebook.com/codibooth.be/">
                                    <i className="fa fa-facebook white-text mr-4"> </i>
                                </a>
                                {/* <a className="tw-ic">
                                    <i className="fa fa-twitter white-text mr-4"> </i>
                                </a> */}
                                {/* <a className="gplus-ic">
                                    <i className="fa fa-google-plus white-text mr-4"> </i>
                                </a>
                                <a className="li-ic">
                                    <i className="fa fa-linkedin white-text mr-4"> </i>
                                </a>
                                <a className="ins-ic">
                                    <i className="fa fa-instagram white-text"> </i>
                                </a> */}
                            </Col>
                        </Row>
                    </Container>
                </ColoredDiv>

                <div className="container text-center text-md-left mt-5">
                    <Row className="mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase font-weight-bold">Codibri BVBA</h6>
                            <StyledHr className="accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                            <p>Codibooth is een product<br />van Codibri BVBA.</p>
                            <p>BTW BE 0659.943.854</p>
                        </div>
                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase font-weight-bold">Nuttige links</h6>
                            <StyledHr className="accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                            <p>
                                {this.state.algemeneVoorwwardenUrl && <a href={this.state.algemeneVoorwwardenUrl}>Algemene voorwaarden</a>}
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase font-weight-bold">Contact</h6>
                            <StyledHr className="accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                                <p>Donkstraat 74 <br />2870 Ruisbroek<br />België</p>
                        </div>
                    </Row>
                </div>
                <div className="footer-copyright text-center py-3">© {moment().format('Y')} Copyright: Toon Janssens</div>
            </footer>
        );
    }
};
import React, { Component } from 'react';
import {Route, Switch } from 'react-router-dom';
import * as Scenes from './scenes';

export default class App extends Component {

    render() {
        return (
            <Switch>
                <Route path="/" exact component={Scenes.Home} />
                <Route path="/reserveer" exact component={Scenes.Order} />
                <Route component={Scenes.NotFound} />
            </Switch>
        )
    }
}
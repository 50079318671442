export default function (state = {
    navBarVisible: true,
    recapthcaToken: null,
}, action) {
    switch (action.type) {
        case 'NAVBAR_VISIBLE':
            const navBarState = Object.assign({}, state);
            navBarState.navBarVisible = action.payload;

            return navBarState;

        case 'SET_RECAPTCHA_TOKEN':
            const captchaState = Object.assign({}, state);
            captchaState.recapthcaToken = action.token;

            return captchaState;
    }

    return state;
};
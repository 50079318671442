import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse, Container } from 'mdbreact';
import { genericHashLink } from 'react-router-hash-link';
import logo from '../assets/codibooth_logo.png';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '../assets/layout.css';
import {connect} from "react-redux";
import Footer from "./footer";
import 'typeface-roboto';
import './layout.css';

const HashNavLink = (props) => {
    let newProps = Object.assign({}, props);

    if (!props.scroll) {
        newProps.scroll = el => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
    }

    return genericHashLink(newProps, NavLink);
};

const mapStateToProps = (state) => {
    return {
        navBarVisible: state.app.navBarVisible,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse : false
        };

        this.onClick = this.onClick.bind(this);
        this.handleNavbarClick = this.handleNavbarClick.bind(this);
    }

    onClick(){
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    handleNavbarClick(){
        this.setState({
            collapse: false
        });
    }

    render(){
        const overlay = <div id="sidenav-overlay" style={{backgroundColor: 'transparent'}} onClick={this.handleNavbarClick}/>

        return (
            <div id="apppage">
                <div>
                    <Navbar dark
                            expand="md"
                            fixed={this.props.scrolling ? "top" : undefined}
                            scrolling={this.props.scrolling}
                            className={this.props.scrolling ? null : 'top-nav-collapse'}
                            style={{display: this.props.navBarVisible ? 'inherit' : 'none'}}
                    >
                        <Container>
                            <NavbarBrand>
                                <HashNavLink to="/#home"><img src={logo} alt="Codibooth" /></HashNavLink>
                            </NavbarBrand>
                            <NavbarToggler onClick = { this.onClick } />
                            <Collapse isOpen = {this.state.collapse} navbar>
                                <NavbarNav right>
                                    <NavItem>
                                        <HashNavLink to="/#home">Onze booth</HashNavLink>
                                    </NavItem>
                                    <NavItem>
                                        <HashNavLink to="/#mogelijkheden">Mogelijkheden</HashNavLink>
                                    </NavItem>
                                    <NavItem>
                                        <HashNavLink to="/#prijzen">Prijzen</HashNavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <HashNavLink to="/#fotos">Foto's</HashNavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <HashNavLink to="/#contact">Contact</HashNavLink>
                                    </NavItem>
                                </NavbarNav>
                            </Collapse>
                        </Container>
                    </Navbar>
                    { this.state.collapse && overlay}
                </div>
                {this.props.children}
                <Footer/>
            </div>
        );
    }
};

Layout.propTypes = {
    scrolling: PropTypes.bool,
    hideNavBar: PropTypes.bool,
};

Layout.defaultProps = {
    hideNavBar: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
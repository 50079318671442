import React from 'react';
import PropTypes from 'prop-types';

const OffsetAnchor = (props) => {
    return (
        <div style={{position: 'relative'}}>
            <div id={props.id} style={{position: 'absolute', top: props.offset, left: 0}}></div>
            {props.children}
        </div>
    );
};

OffsetAnchor.propTypes = {
    id: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired
}

export default OffsetAnchor;
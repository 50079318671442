import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/storage';

var config = {
    apiKey: "AIzaSyBHn3rA5A9thcbsaBENj-TVy3fAAYbi2Nk",
    authDomain: "codibooth-65202.firebaseapp.com",
    databaseURL: "https://codibooth-65202.firebaseio.com",
    projectId: "codibooth-65202",
    storageBucket: "codibooth-65202.appspot.com",
    messagingSenderId: "779653351120"
  };

var fire = firebase.initializeApp(config);

if (process.env.NODE_ENV === 'development') {
  fire.functions().useFunctionsEmulator('http://localhost:5001')
}

export default fire;
import React from 'react';
import { Container, Row, Col } from 'mdbreact';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import styled from 'styled-components';

const images = [
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(117).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(98).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(131).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(123).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(118).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(128).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(132).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(115).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(133).jpg'
];

const smallImages = [
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(117).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(98).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(131).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(123).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(118).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(128).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(132).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(115).jpg',
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(133).jpg'
];

const Figure = styled.figure`
    margin: 0;
    float: left;
    cursor: pointer;
`;

const StyledCol = styled(Col)`
  padding: 0 !important;
`;

export default class Photobox
    extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };

        this.onImageClick = this.onImageClick.bind(this);
    }

    onImageClick(index) {
        return () => {
            this.setState({ photoIndex: index, isOpen: true });
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        return(
            <div>
                <Container>
                    <Row>
                        {smallImages.map((image, index) => (
                            <StyledCol md="4" key={index}>
                                <Figure>
                                    <img src={image} alt="Gallery" className="img-fluid" onClick={this.onImageClick(index)} />
                                </Figure>
                            </StyledCol>
                        ))}
                    </Row>
                </Container>

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + '/' + images.length}
                        onCloseRequest={() => {
                            this.setState({ isOpen: false });
                        }}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                        enableZoom={false}
                        reactModalStyle={{zIndex: 1030}}
                    />
                )}
            </div>
        );
    }
};
import React from 'react';
import { Row, Col, MDBAlert, Container } from 'mdbreact';
import OffsetAnchor from "~/components/offset-anchor";
import Form from './components/form';
import firebase from '~/services/firebase';
import {connect} from "react-redux";
import Layout from "~/components/layout";
import PhotoboothImage from './assets/photobooth.png';
import Mailto from 'react-protected-mailto';
import { format } from 'date-fns'

const submitOrder = firebase.functions('europe-west1').httpsCallable('submitOrder');

const mapStateToProps = (state) => ({
    token: state.app.recapthcaToken,
});

class Scene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            formKey: 1,
            isSent: false,
            hasError: false,
        };

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submit(values) {
        submitOrder({...values})
            .then(response => {
                this.setState((state) => ({
                    formKey: state.formKey + 1,
                    isSent: true,
                }));
            })
            .catch(error => {
                this.setState((state) => ({
                    isSent: false,
                    hasError: true
                }));
            });
    }

    render() {
        let params = new URLSearchParams(this.props.location.search);

        return(
            <Layout>
                <Container>
                    <section>
                        <OffsetAnchor id="order" offset={-100}>
                            <h2 className="h1-responsive font-weight-bold text-center my-5">Plaats je bestelling</h2>
                        </OffsetAnchor>
                        <Row>
                            <Col md="4">
                                <img src={PhotoboothImage} alt="Photobooth" />
                            </Col>
                            <Col md="8">
                                {this.state.isSent && (
                                    <MDBAlert color="success">
                                        <h4 className="alert-heading">We hebben uw bestelling goed ontvangen.</h4>
                                        <p>We contacteren u zo spoedig mogelijk om uw bestelling te bevestigen.</p>
                                    </MDBAlert>
                                )}
                                {this.state.hasError && (
                                    <MDBAlert color="danger">
                                        <h4 className="alert-heading">Er ging helaas iets mis.</h4>
                                        <p>Gelieve ons te contacteren via e-mail: 
                                            <Mailto 
                                                email="info@codibooth.be" 
                                                obfuscate={true} 
                                                headers={{
                                                    subject: 'Bestelling Codibooth', 
                                                }}
                                            />
                                        </p>
                                    </MDBAlert>
                                )}
                                <Form onSubmit={this.submit} key={this.state.formKey} initialValues={{package: params.get('package'), date: format(new Date(), 'yyyy-MM-dd') }} />
                            </Col>
                        </Row>
                    </section>
                </Container>
            </Layout>
        );
    }
};

export default connect(
    mapStateToProps
)(Scene);
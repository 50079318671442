import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse, Mask, Row, Col, Fa, Button, View, Container, FormInline } from 'mdbreact';

export default class FeatureRow extends React.Component {
    render(){
        return (
            <Row className="mb-3">
                <Col size="2">
                    <Fa icon={this.props.icon} size="2x" style={{color: "#6db9bc"}}/>
                </Col>
                <Col size="10">
                    <h5 className="font-weight-bold mb-3">{this.props.title}</h5>
                    <p className="grey-text">
                        {this.props.children}
                    </p>
                </Col>
            </Row>
        );
    }
};

FeatureRow.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
}
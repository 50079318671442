import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'mdbreact';
import priceBackground from '../assets/price-bg.png';
import { withRouter } from 'react-router-dom';

const OrderButton = withRouter((props) => (
    <Button 
        color="danger" 
        className="mb-4" 
        onClick={() => { props.history.push('/reserveer?package=' + props.id)}}
    >Bestellen</Button>
  ))

export default class PriceCard extends Component {
    renderBody() {
        return (
            <CardBody>
                <h5 className="font-weight-bold mt-3">{this.props.title}</h5>
                <div className="price pt-0">
                    <h1 className="number red-text mb-0">€ {this.props.price}</h1>
                </div>
                <ul className="striped mb-1">
                    {this.props.children}
                </ul>
                <OrderButton image={this.props.image} id={this.props.id} />
            </CardBody>
        );
    }

    render() {
        if (this.props.image) {
            return (
                <Card pricing className="card-image" style={{backgroundImage: `url(${priceBackground})`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', marginTop: "-1em"}}>
                    <div className="text-white text-center pricing-card d-flex align-items-center py-3 px-3 rounded" style={{backgroundColor: 'rgba(109, 185, 188, 0.59)'}}>
                        {this.renderBody()}
                    </div>
                </Card>
            );

        }
        return(
            <Card pricing>
                {this.renderBody()}
            </Card>
        );
    };
}

PriceCard.propTypes = {
    title: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.bool,
};




import React from 'react';
import {Container, View, Row, Col, Mask} from 'mdbreact';
import Layout from "../../components/layout";

export default class Scene extends React.Component {
    render() {
        return (
            <Layout>
                <Container>
                    <Row>
                        <Col>Not found</Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}
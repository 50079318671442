import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImage, CardBody, CardTitle, CardText, CardFooter, Fa, Tooltip, Badge, Button } from 'mdbreact';
import './price.css';
import PriceCard from "./price-card";
import styled from 'styled-components';
import priceData from './prices.json';

const Item = styled.li`
    text-decoration: ${props => props.disable ? "line-through" : "inherit"};
`;

export default class PriceTable extends Component {
    render() {
        return(
            <Container className="price-table">
                <section className="text-center my-5">
                    <Row>
                        {priceData.packs.map(pack => 
                            <Col lg="4" md="12" className="mb-lg-0 mb-4" key={pack.title}>
                                <PriceCard 
                                    title={pack.title} 
                                    price={pack.price} 
                                    image={pack.highlight}
                                    id={pack.id}
                                >
                                    {priceData.features.map(feature => {
                                        let text = feature.name;
                                        if (pack.features.indexOf(feature.id) < 0) {
                                            text = '-';
                                        }
                                        return (
                                            <Item key={feature.id}>
                                                <p>{text}</p>
                                            </Item>
                                        );
                                    })}
                                </PriceCard>
                            </Col>
                        )}
                    </Row>
                </section>
            </Container>
        );
    };
}
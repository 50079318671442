import React from 'react';
import { Row, Col, MDBAlert, MDBBtn } from 'mdbreact';
import OffsetAnchor from "~/components/offset-anchor";
import ContactForm from './components/ContactForm';
import firebase from '~/services/firebase';
import Mailto from 'react-protected-mailto';

const submitContactForm = firebase.functions('europe-west1').httpsCallable('submitContactForm');

class Scene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSent: false,
            hasError: false,
            error: '',
            question: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values, { resetForm, setSubmitting }) {
        const submitted = submitContactForm(values);
        
        submitted.then(() => {
            this.setState({ isSent: true, hasError: false, error: '', question: '' });
            setTimeout(() => { this.setState({isSent: false})}, 30000);
            resetForm();
        }, (error) => {
            this.setState({isSent: false, hasError: true, error: error, question: values.question});
            setSubmitting(false);
        });
    }

    render() {
        return(
            <section>
                <OffsetAnchor id="contact" offset={-100}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">Contacteer ons</h2>
                </OffsetAnchor>
                <Row>
                    <Col md="12">
                        {this.state.isSent && (
                            <MDBAlert color="success">
                                <h4 className="alert-heading">We hebben uw vraag goed ontvangen</h4>
                                <p>We probren uw vraag zo spoedig mogelijk te beantwoorden.</p>
                            </MDBAlert>
                            
                        )}
                        {this.state.hasError && (
                            <div style={{marginBottom: '50px'}}>
                                <MDBAlert color="danger" >
                                    <h4 className="alert-heading">Er ging iets mis tijdens het versturen van je vraag.</h4>
                                    <p>Om zeker te zijn dat we je kunnen verder helpen, 
                                        vragen we je om je vraag via mail te versturen naar &nbsp;
                                        <Mailto 
                                            email="info@codibooth.be" 
                                            obfuscate={true} 
                                            headers={{
                                                subject: 'Vraag over Codibooth', 
                                                body: this.state.question}
                                            }/>
                                    </p>
                                </MDBAlert>
                            </div>
                        )}
                        <ContactForm onSubmit={this.handleSubmit} />
                    </Col>
                </Row>
            </section>
        );
    }
};

export default Scene;
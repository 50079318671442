import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { MDBBtn, MDBIcon } from "mdbreact";
import React from "react";
import Recaptcha from "react-recaptcha";
import { Field, reduxForm } from "redux-form";
import { DatePicker } from "@material-ui/pickers";

const renderTextField = (props) => {
  const hasError = props.meta.invalid && props.meta.touched;
  const textFieldProps = Object.assign({}, props.input, {
    label: props.label,
    select: props.select,
    multiline: props.multiline,
    error: hasError,
    helperText: hasError ? props.meta.error : undefined,
  });

  return (
    <TextField {...textFieldProps} fullWidth>
      {props.children}
    </TextField>
  );
};

const renderDatePickerField = (props) => {
  const hasError = props.meta.invalid && props.meta.touched;
  const textFieldProps = Object.assign({}, props.input, {
    label: props.label,
    select: props.select,
    multiline: props.multiline,
    error: hasError,
    helperText: hasError ? props.meta.error : undefined,
    type: 'date',
  });

  return (
    <TextField {...textFieldProps} fullWidth>
      {props.children}
    </TextField>
  );
};

const required = (value) => (value ? undefined : "Verplicht");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Ongeldig email adres"
    : undefined;

class OrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
    };

    this.setRecaptchaToken = this.setRecaptchaToken.bind(this);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  setRecaptchaToken(token) {
    this.setState({ token: token });
    this.props.change("token", token);
  }

  render() {
    const packages = [
      { value: "basic", label: "Basis pakket" },
      { value: "print", label: "Print pakket" },
      { value: "premium", label: "Premium pakket" },
    ];

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Grid item xs={8}>
          <Grid container spacing={24} justify="center">
            <Grid item xs={12}>
              <Field
                name="firstName"
                component={renderTextField}
                props={{
                  label: "Voornaam",
                  required,
                }}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="lastName"
                component={renderTextField}
                props={{
                  label: "Naam",
                  required,
                }}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="email"
                component={renderTextField}
                type="email"
                props={{
                  label: "Email adres",
                }}
                validate={[required, email]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="date"
                component={renderDatePickerField}
                props={{
                  label: "Datum",
                  required,
                }}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="package"
                component={renderTextField}
                props={{
                  label: "Pakket",
                  select: true,
                }}
                validate={[required]}
              >
                {packages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comment"
                component={renderTextField}
                props={{
                  label: "Opmerkingen",
                  multiline: true,
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px " }}>
              <Recaptcha
                sitekey="6Lfg4sUUAAAAACia034KsEfQXYFKk81LqzZRgPLW"
                render="explicit"
                verifyCallback={this.setRecaptchaToken}
                onloadCallback={() => {}}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBBtn
                outline
                color="info"
                type="submit"
                disabled={this.state.token == null}
              >
                Bestel <MDBIcon icon="paper-plane-o" className="ml-1" />
              </MDBBtn>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default reduxForm({
  form: "order",
})(OrderForm);

import React from 'react';
import { Container, Row, Col } from 'mdbreact';
import OffsetAnchor from "../../../../components/offset-anchor";
import Photobox from "./components/photobox";

export default class Scene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        return(
            <section>
                <OffsetAnchor id="fotos" offset={-100}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">Foto's</h2>
                </OffsetAnchor>
                <Row>
                    <Col md="12">
                        <Photobox/>
                    </Col>
                </Row>
            </section>
        );
    }
};
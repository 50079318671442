import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app";
import store from "./services/store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme, ThemeProvider } from "@material-ui/core";

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#6db9bc',
    },
  },
});

ReactDom.render(
  <ThemeProvider theme={defaultMaterialTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById("app")
);

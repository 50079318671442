import thunk from "redux-thunk";
import {applyMiddleware, combineReducers, createStore} from "redux";

import { routerReducer as routing } from 'react-router-redux';
import {reducer as form} from "redux-form";
import app from "./reducers/appReducer";

export default createStore(
    combineReducers({
        routing,
        form,
        app,
    }),
    applyMiddleware(
        thunk
    ),
);
import React from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse, Mask, Row, Col, Fa, Button, View, Container, FormInline } from 'mdbreact';
import PriceTabel from "./components/price-table";
import OffsetAnchor from "../../../../components/offset-anchor";

export default class Scene extends React.Component {
    render(){
        return (
            <section>
                <OffsetAnchor id="prijzen" offset={-100}>
                    <h2 className="h1-responsive font-weight-bold text-center my-5">Onze prijzen</h2>
                </OffsetAnchor>
                <Row className={'justify-content-md-center'}>
                    <Col md="12">
                        <PriceTabel/>
                    </Col>
                </Row>
            </section>
        );
    }
};
import { Field, Form, Formik } from 'formik';
import { MDBAlert, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import React from 'react';
import Recaptcha from 'react-recaptcha';
import * as yup from 'yup';

const MDBFormikInput = ({ field, form, ...other }) => {
    return <MDBInput {...field} {...other} />;
}

const formFields = {
    name: {
        type: "text",
        label: "Je naam",
        icon: "user"
    },
    email: {
        type: "email",
        label: "E-mail",
        icon: "envelope"
    },
    question: {
        type: "textarea",
        label: "Je vraag",
        icon: "pencil",
        rows: 2,
    }
}

const ContactForm = ({ onSubmit}) => {
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                question: '',
                recaptcha: '',
            }}
            validationSchema={yup.object().shape({
                name: yup.string().required(),
                email: yup.string().email().required(),
                question: yup.string().required(),
                recaptcha: yup.string().required(),
            })}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {({
                errors,
                isSubmitting,
                setFieldValue,
            }) => {
                const {recaptcha: recaptchaError, ...fieldErrors} = errors;

                return (
                    <Form noValidate>
                        {Object.keys(fieldErrors).length > 0 && (
                            <MDBAlert color="danger">
                                <h4 className="alert-heading">Niet alle velden zijn ingevuld</h4>
                                <p>
                                    Volgende velden zijn verplicht: &nbsp;
                                    {Object.keys(fieldErrors).length > 0 && (
                                        Object.keys(fieldErrors)
                                        .map(fieldName => {
                                            if (formFields[fieldName] && formFields[fieldName].label) {
                                                return formFields[fieldName].label;
                                            }
                                            
                                            console.warn(`Field "${fieldName}" is not defined in formFields variable`);
                                            
                                            return '';
                                        })
                                        .join(', ')
                                    )}
                                </p>
                            </MDBAlert>
                        )}
                        <div className="grey-text">
                            {Object.keys(formFields).map((name) => (
                                <Field 
                                    key={name}
                                    name={name} 
                                    component={MDBFormikInput}
                                    group={true}
                                    className={errors[name] && 'invalid'}
                                    {...formFields[name]}
                                />
                            ))}
                        </div>
                        <div style={{ marginLeft: "40px" }}>
                            <Recaptcha
                                sitekey="6Lfg4sUUAAAAACia034KsEfQXYFKk81LqzZRgPLW"
                                render="explicit"
                                verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                onloadCallback={() => { }}
                            />
                            {recaptchaError && (
                                <MDBAlert color="danger">
                                    Dit veld is verplicht aan te vinken.
                                </MDBAlert>
                            )}
                        </div>
                        <div className="text-center">
                            <div>
                                <MDBBtn color="info" type="submit" disabled={isSubmitting}>
                                    Verstuur <MDBIcon icon="paper-plane-o" className="ml-1" />
                                </MDBBtn>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ContactForm;
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./header.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#apppage .gradient {\n    background: -moz-linear-gradient(45deg, rgba(11, 45, 161, 0.4), rgba(29, 210, 177, 0.4) 100%);\n    background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.4), rgba(29, 210, 177, 0.4) 100%);\n    background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.4)), to(rgba(29, 210, 177, 0.4)));\n    background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.4), rgba(29, 210, 177, 0.4) 100%);\n}\n\n#apppage .view {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center center;\n    height: 100vh;\n}\n\n#apppage h6 {\n    line-height: 1.7;\n}\n\n#apppage .navbar {\n    transition: background .5s ease-in-out,padding .5s ease-in-out;\n}\n\n#apppage .top-nav-collapse {\n    background: #6db9bc !important;\n}\n\n@media (max-width: 768px) {\n    #apppage .navbar:not(.top-nav-collapse) {\n        background: #6db9bc !important;\n    }\n}", ""]);
// Exports
module.exports = exports;
